import React, { useRef } from 'react'
import Hero1 from "../images/Homepage/P1.png"
import Hero2 from "../images/Homepage/P2.png"
import Hero3 from "../images/Homepage/P3.png"
import Hero4 from "../images/Homepage/P4.png"
import Hero5 from "../images/Homepage/P5.png"
import { ReactComponent as Left } from '../images/Homepage/Left.svg'
import { ReactComponent as Right } from '../images/Homepage/Right.svg'
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import {  Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const BannerPage = () => {
  const Images = [Hero1, Hero2, Hero3, Hero4, Hero5]
  const sliderRef = useRef()
  const handleNext = () => {
    sliderRef.current.swiper.slideNext()
  }
  const handlePrev = () => {
    sliderRef.current.swiper.slidePrev()
  }
  return (
    <div className='relative'>
      <Swiper
      ref={sliderRef}
        slidesPerView={1}
        spaceBetween={0}
        pagination={{
          clickable: false,
        }}
        modules={[Pagination]}
        className="mySwiper newRes"
        loop={true}
      >
        {Images.map((itm, idx) => (
          <SwiperSlide key={idx} style={{ background: "transparent" }}>
            <img src={itm} alt="img" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='absolute bottom-[50px] max_xl1460:bottom-9 max_xl1460:right-9 max_ right-[50px] max_500:right-[10px] max_500:bottom-[8px] z-40 flex gap-4 max_500:gap-2'>
        <div className='F_JC_IC w-[50px] h-[50px] max_xl1460:w-9 max_xl1460:h-9 max_500:w-6 max_500:h-6 bg-white opacity-60 border-[1px] border-Primary rounded-[5px] cursor-pointer' onClick={handlePrev}>
          <Right className='max_500:w-[8px] max_500:h-[15px]' />
        </div>
        <div className='F_JC_IC w-[50px] h-[50px] max_xl1460:w-9 max_xl1460:h-9 max_500:w-6 max_500:h-6 bg-white opacity-60 border-[1px] border-Primary rounded-[5px] cursor-pointer' onClick={handleNext}>
          <Left className='max_500:w-[8px] max_500:h-[15px]' />
        </div>
      </div>
    </div>
  )
}

export default BannerPage
