import React from 'react'
import ButtonImg from '../images/Gallary/Button.png'

const ButtonComp = ({ text, left,handleOnClick }) => {
  return (
    // <div className='relative cursor-pointer w-max max_500:w-[160px]' >
    //   <img src={ButtonImg} alt='button' />


    //   <p className={`absolute text-white top-[9px] max_500:top-[7px] left-[66px] Text18Sans max_500:text-[15px] font-medium ${left ? 'left-[65px] max_500:left-[57px]':'left-[54px] max_500:left-[48px]'}`}>{text}</p>
    //   {/* <button className="customButton">
    //     {text}
    //     <div className="customDes"></div>
    //   </button> */}
    // </div>
    <button class="button" onClick={handleOnClick}>
      <span class="text">{text}</span>
      <div class="wave"></div>
    </button>

  )
}

export default ButtonComp
