import React from 'react'

const InputComponent = ({placeholder}) => {
  return (
    <div>
      <input className='w-full px-4 py-[11px] bg-[#F5F5F5] rounded-[5px] focus:outline-none Text18Sans text-gray' placeholder={placeholder} />
    </div>
  )
}

export default InputComponent
