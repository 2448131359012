import React, { useState } from 'react'
import GetInTouchImg from '../images/Homepage/GetInTouchImg.png'
import GetInTouchImgMB from '../images/Homepage/GetInTouchMB.png'
import HeadingComp from './HeadingComp'
import InputComponent from './InputComponent'
import InputTextArea from './InputTextArea'
import ButtonComp from './ButtonComp'
import { Email, Location, Phone } from '../images/icons'

const GetInTouch = ({ marginTop }) => {
  const [socialIndex, setSocialIndex] = useState(null)
  return (
    <div className={`max-w-[1212px] max_1236:max-w-[1024px] max_1024:max-w-[768px] max_768:max-w-full max_768:mx-10 max_500:mx-5 m-auto flex mb-[100px] max_768:mb-[60px] max_500:mb-[50px] h-full max_500:flex-col ${marginTop ? 'mt-[50px]' : 'mt-[100px] max_768:mt-[60px] max_500:mt-[50px]'}`} style={{
      boxShadow: ' 2px 2px 12px 0px #0000001A'
    }}>
      <div className='w-[54%] relative max_500:hidden'>
        <img src={GetInTouchImg} alt='image' className='w-full h-full' />
        <div className='absolute bottom-0 left-0 flex flex-col gap-5 px-[30px] max_768:px-[10px] max_768:justify-between max_768:gap-2 pb-[30px] max_1024:flex-row max_720:flex-col'>
          <div className='flex gap-5' onMouseEnter={() => setSocialIndex(1)} onMouseLeave={() => setSocialIndex(null)}>
            <div className={`w-[50px] h-[50px] ${socialIndex === 1 ? 'bg-Secondary_Primary' : 'bg-Primary'} rounded-full flex justify-center items-center `}>
              <Email color={"#fff"} />
            </div>
            <div className={`Text18Sans max_1024:hidden ${socialIndex === 1 ? 'text-Secondary_Primary':'text-black'}`}><p>Mail</p><p>trisollubricants@gmail.com</p></div>
          </div>
          <div className='flex gap-5' onMouseEnter={() => setSocialIndex(2)} onMouseLeave={() => setSocialIndex(null)}>
            <div className={`w-[50px] h-[50px] ${socialIndex === 2 ? 'bg-Secondary_Primary' : 'bg-Primary'} rounded-full flex justify-center items-center`}>
              <Phone color="#fff" />
            </div>
            <div className={`Text18Sans max_1024:hidden ${socialIndex === 2 ? 'text-Secondary_Primary':'text-black'}`}><p>Phone</p><p>+91 63545 27074</p></div>
          </div>
          <div className='flex gap-5' onMouseEnter={() => setSocialIndex(3)} onMouseLeave={() => setSocialIndex(null)}>
            <div className={`w-[50px] h-[50px] ${socialIndex === 3 ? 'bg-Secondary_Primary' : 'bg-Primary'} rounded-full flex justify-center items-center`}>
              <Location color="#fff" />
            </div>
            <div className={`Text18Sans max-w-[280px] max_1236:max-w-[220px] max_1024:hidden ${socialIndex === 3 ? 'text-Secondary_Primary':'text-black'}`}><p>Location</p><p>E-37, Hindva Dreams Industrial Park, Dhoran - 394155. (Gujarat) INDIA</p></div>
          </div>
        </div>
      </div>
      <div className='hidden max_500:block w-[100%] h-[440px] relative'>
        <img src={GetInTouchImgMB} alt='image' className='w-full h-full' />
        <div className='absolute bottom-[30px] flex justify-between px-10 max_350:px-7 w-full'>
          <div className='w-[50px] h-[50px] bg-Primary rounded-full flex justify-center items-center'>
            <Email color="#fff" />
          </div><div className='w-[50px] h-[50px] bg-Primary rounded-full flex justify-center items-center'>
            <Phone color="#fff" />
          </div><div className='w-[50px] h-[50px] bg-Primary rounded-full flex justify-center items-center'>
            <Location color="#fff" />
          </div>
        </div>
      </div>
      <div className='flex flex-col py-[70px] px-[50px] max_1024:py-10 max_1024:px-10 w-full max_768:py-5 max_768:px-5'>
        <HeadingComp head1="Get in" head2="Touch" center={true} />
        <p className='Text18Sans text-Light_black mt-2'>We will answer your questions and problems</p>

        <div className='grid grid-cols-2 gap-[21px] max_768:gap-4 mt-[40px] max_768:mt-[20px] max_768:grid-cols-1'>

          <InputComponent placeholder="First Name" />
          <InputComponent placeholder="Last Name" />
          <InputComponent placeholder="Email" />
          <InputComponent placeholder="Phone" />
        </div>
        <div className='mt-[21px] max_768:mt-4'>
          <InputTextArea placeholder="Message" />
        </div>
        <div className='mt-[4%] max_768:mt-[2%]'>

          <ButtonComp text="Submit" left="65px" />
        </div>
      </div>
    </div>
  )
}

export default GetInTouch
